<template>
  <v-img class="c-img-book-cover" v-bind="customAttrs" @error="errorHandler" v-on="$listeners">
    <template #placeholder>
      <div
        class="error-placeholder d-flex justify-center align-center f12 px-2"
        v-if="urlEmpty || error"
      >{{title}}</div>
      <div class="loading-placeholder" v-else>
        <c-loading-circle bg-color="transparent"></c-loading-circle>
      </div>
    </template>
  </v-img>
</template>

<script>
import CLoadingCircle from "../c-loading-circle";
export default {
  name: "c-img-book-cover",
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      error: false,
      defaultAttrs: {
        "aspect-ratio": 105 / 140,
      },
    };
  },
  methods: {
    errorHandler() {
      this.error = true;
    },
  },
  computed: {
    customAttrs() {
      const { defaultAttrs, $attrs: attrs } = this;
      return Object.assign({}, defaultAttrs, attrs);
    },
    urlEmpty() {
      const { $attrs: attrs } = this;
      return attrs.src ? false : true;
    },
  },
  components: {
    CLoadingCircle,
  },
};
</script>

<style lang="scss" scoped>
.c-img-book-cover {
  border-radius: 6px;

  .loading-placeholder {
    background-color: var(--v-divider-base);
    width: 100%;
    height: 100%;
  }
  .error-placeholder {
    width: 100%;
    height: 100%;
    background: url("./placeholder-error.png");
    background-size: contain;
    color: #fff;
  }
}
</style>