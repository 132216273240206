<template>
  <div @click="clickBook">
    <v-row class="this-item-container pa-0 ma-0" align="center">
      <v-col class="pa-0 ma-0" cols="auto">
        <c-img-book-cover
          class="this-item-book-img"
          :width="74"
          :height="98"
          :max-width="74"
          :max-height="98"
          :title="item.name"
          :src="item.cover"
        ></c-img-book-cover>
      </v-col>

      <v-col class="this-item-col-2 pa-0 ma-0">
        <v-clamp class="this-item-book-name pa-0" autoresize :max-lines="2">{{
          item.name
        }}</v-clamp>
        <v-clamp class="this-item-book-author pa-0" autoresize :max-lines="2">{{
          item.writer
        }}</v-clamp>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VClamp from "vue-clamp";
import CImgBookCover from "@/components/c-img-book-cover/c-img-book-cover";
export default {
  name: "pc-book-detail-recommend-item-pc",
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  components: {
    VClamp,
    CImgBookCover,
  },
  computed: {},
  methods: {
    clickBook() {
      //跳转书籍详情
      this.$router.replace({
        name: "pcBookDetail",
        params: {
          bookId: this.item.id.toString(),
        },
      });
      this.$track.event({
        category: "book",
        name: "visit",
        value: this.item.id.toString(),
        params: {
          referer_action: "book_page",
        },
      });
    },
  },
  watch: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.this-item-container {
  width: 249px;
  height: 127px;
  &:hover {
    background-color: rgba(201, 201, 201, 0.3);
  }
  .this-item-book-img {
    margin-left: 9px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.14);
  }
  .this-item-col-2 {
    height: 100%;
  }
  .this-item-book-name {
    line-height: 22px;
    margin: 21px 0px 0px 12px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #444444;
  }
  .this-item-book-author {
    line-height: 17px;
    margin: 10px 0px 0px 12px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
}
</style>
