<template>
  <v-row class="this-container ma-0" v-if="books && books.length > 0">
    <v-col class="pa-0 ma-0">
      <div class="this-book-recommend-s">书籍推荐</div>

      <v-row class="this-book-recommend pa-0">
        <pc-book-detail-recommend-item-pc
          v-for="book in books"
          :key="book.id"
          :item="book"
        ></pc-book-detail-recommend-item-pc>
      </v-row>
      <!-- <v-row
        class="this-book-recommend pa-0"
        justify="space-between"
        v-show="screenMode === 'pc'"
      >
        <pc-book-detail-recommend-item-pc
          v-if="books[0]"
          :item="books[0]"
        ></pc-book-detail-recommend-item-pc>
        <pc-book-detail-recommend-item-pc
          v-if="books[1]"
          :item="books[1]"
        ></pc-book-detail-recommend-item-pc>
        <pc-book-detail-recommend-item-pc
          v-if="books[2]"
          :item="books[2]"
        ></pc-book-detail-recommend-item-pc>
      </v-row>

      <v-row
        class="this-book-recommend pa-0"
        justify="space-between"
        v-show="screenMode === 'pad'"
      >
        <pc-book-detail-recommend-item-pc
          v-if="books[0]"
          :item="books[0]"
        ></pc-book-detail-recommend-item-pc>
        <pc-book-detail-recommend-item-pc
          v-if="books[1]"
          :item="books[1]"
        ></pc-book-detail-recommend-item-pc>
      </v-row>
      <v-row
        class="this-book-recommend pa-0"
        justify="space-between"
        v-show="screenMode === 'pad'"
      >
        <pc-book-detail-recommend-item-pc
          v-if="books[2]"
          :item="books[2]"
        ></pc-book-detail-recommend-item-pc>
        <pc-book-detail-recommend-item-pc
          v-if="books[3]"
          :item="books[3]"
        ></pc-book-detail-recommend-item-pc>
      </v-row>

      <v-row
        class="this-book-recommend pa-0"
        justify="space-between"
        v-show="screenMode === 'phone'"
      >
        <pc-book-detail-recommend-item-phone
          v-if="books[0]"
          :item="books[0]"
        ></pc-book-detail-recommend-item-phone>
        <pc-book-detail-recommend-item-phone
          v-if="books[1]"
          :item="books[1]"
        ></pc-book-detail-recommend-item-phone>
        <pc-book-detail-recommend-item-phone
          v-if="books[2]"
          :item="books[2]"
        ></pc-book-detail-recommend-item-phone>
        <pc-book-detail-recommend-item-phone
          v-if="books[3]"
          :item="books[3]"
        ></pc-book-detail-recommend-item-phone>
      </v-row> -->
    </v-col>
  </v-row>
</template>

<script>
import { getBookDetailRecommend } from "@/api/api-pc";
import PcBookDetailRecommendItemPc from "@/views/pc/components/pc-book-detail-recommend-item-pc";
// import PcBookDetailRecommendItemPhone from "@/views/pc/components/pc-book-detail-recommend-item-phone";
export default {
  name: "pc-book-detail-recommend",
  props: {
    isTeacher: {
      type: Boolean,
      default: false,
    },
    bookId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      books: [], //书籍推荐列表
    };
  },
  components: {
    PcBookDetailRecommendItemPc,
    // PcBookDetailRecommendItemPhone,
  },
  computed: {
    // screenMode() {
    //   switch (this.$vuetify.breakpoint.name) {
    //     case "xs":
    //       return "phone";
    //     case "sm":
    //       return "pad";
    //     case "md":
    //       return "pad";
    //     case "lg":
    //       return "pc";
    //     case "xl":
    //       return "pc";
    //     default:
    //       return "pc";
    //   }
    // },
  },
  methods: {
    //网络请求
    getData() {
      let userRole = this.isTeacher ? "教师" : "学生"; //用户角色
      getBookDetailRecommend(this.bookId)
        .then((res) => {
          if (!res && !res.books) {
            console.log(">>>>>", "获取" + userRole + "书籍详情书籍推荐错误");
          } else {
            console.log(">>>>>", "获取" + userRole + "书籍详情书籍推荐成功");
            this.books = res.books;
            if (this.books.length > 3) {
              this.books.splice(3);
            }
          }
        })
        .catch(({ res, statusCode }) => {
          console.log(">>>>>", "获取" + userRole + "书籍详情书籍推荐失败");
          if (!res || !res.message) {
            return;
          }

          this.showSnackbar({ msg: res.message });
        });
    },
  },
  watch: {},
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.this-container {
  padding: 40px 71px 0px;
  .this-book-recommend-s {
    height: 20px;
    line-height: 20px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #444444;
  }
  .this-book-recommend {
    margin: 30px 0px 0px;
  }
}
</style>
