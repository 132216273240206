<template>
  <v-row
    class="c-loading flex-column ma-0"
    :style="cLoadingStyle"
    align="center"
    justify="center"
  >
    <v-img max-width="60" max-height="60" class="img" src="./loading_new.gif" />
    <div class="f12 minor--text mt-2">加载中，请稍候...</div>
  </v-row>
</template>

<script>
export default {
  name: "c-loading",
  props: {
    width: {
      type: [String, Number],
      default: "100%",
    },
    height: {
      type: [String, Number],
      default: "100%",
    },
    background: {
      type: String,
      default: "white",
    },
  },
  computed: {
    cLoadingStyle() {
      const style = {};
      const { width, height } = this;
      Object.assign(style, { width: isNaN(width) ? width : `${width}px` });
      Object.assign(style, { height: isNaN(height) ? height : `${height}px` });
      Object.assign(style, { background: this.background });
      return style;
    },
  },
};
</script>

<style lang="scss" scoped>
.c-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
</style>