<template>
  <v-row class="this-container ma-0">
    <v-col class="pa-0 ma-0">
      <div class="this-book-note-s">读书笔记</div>
      <v-row
        class="this-book-note-empty pa-0"
        justify="center"
        v-show="showEmpty"
        >暂无笔记哦，快去成为做笔记第一个人吧～</v-row
      >

      <v-col class="this-book-note pa-0" v-show="!showEmpty">
        <pc-book-detail-note-item
          v-for="(note, index) in notes"
          :key="index"
          :item="note"
        ></pc-book-detail-note-item>
      </v-col>

      <infinite-loading
        class="mt-5"
        @infinite="loadMore"
        :distance="10"
        v-show="!showEmpty"
      >
        <div slot="no-more"></div>
      </infinite-loading>
    </v-col>
  </v-row>
</template>

<script>
import { getBookDetailAfterReading } from "@/api/api-pc";
import PcBookDetailNoteItem from "@/views/pc/components/pc-book-detail-note-item";
export default {
  name: "pc-book-detail-note",
  props: {
    bookId: {
      type: String,
      default: null,
    },
    schoolId: {
      type: Number,
      default: 0,
    },
    isTeacher: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      notes: [], //读书笔记列表
      showEmpty: false, //是否显示暂无笔记
      page: 1, //分页下标
    };
  },
  components: {
    PcBookDetailNoteItem,
  },
  computed: {},
  methods: {
    //网络请求
    getData($state) {
      let userRole = this.isTeacher ? "教师" : "学生"; //用户角色
      getBookDetailAfterReading(this.schoolId, this.bookId, this.page, 10)
        .then((res) => {
          if (!res) {
            console.log(">>>>>", "获取" + userRole + "书籍详情读书笔记错误");
            if (this.notes.length === 0) {
              this.showEmpty = true;
            }
          } else {
            console.log(">>>>>", "获取" + userRole + "书籍详情读书笔记成功");
            if (res.comments && res.comments.length > 0) {
              this.notes.push(...res.comments);
              if ($state) {
                $state.loaded();
              }
              this.page++;
            } else {
              if (this.notes.length === 0) {
                this.showEmpty = true;
              }
              if ($state) {
                $state.complete();
              }
            }
          }
        })
        .catch(({ res, statusCode }) => {
          console.log(">>>>>", "获取" + userRole + "书籍详情读书笔记失败");
          if (this.page === 0) {
            this.showEmpty = true;
          }
          if ($state) {
            $state.complete();
          }
          if (!res || !res.message) {
            return;
          }

          this.showSnackbar({ msg: res.message });
        });
    },
    //加载更多
    loadMore($state) {
      this.getData($state);
    },
  },
  watch: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.this-container {
  padding: 40px 80px;
  .this-book-note-s {
    height: 20px;
    line-height: 20px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #444444;
  }
  .this-book-note-empty {
    line-height: 30px;
    margin: 60px 0px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #c4c4c4;
  }
  .this-book-note {
    margin: 20px 9px;
  }
}
</style>
