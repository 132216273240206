<template>
  <v-col class="this-container pa-0 ma-0">
    <v-row class="this-row-1 ma-0">
      <v-col class="this-col-1 pa-0" cols="auto" @click="clickBtn">
        <c-img-book-cover
          class="this-book-img"
          :width="168"
          :height="224"
          :max-width="168"
          :max-height="224"
          :title="book.name"
          :src="book.cover"
        ></c-img-book-cover>
      </v-col>

      <v-col class="this-col-2 pa-0">
        <v-clamp class="this-book-name pa-0" autoresize :max-lines="2">{{
          book.name
        }}</v-clamp>
        <v-clamp class="this-book-author pa-0" autoresize :max-lines="2">{{
          book.writer
        }}</v-clamp>
        <v-clamp
          class="this-book-publication-date pa-0 ma-0"
          autoresize
          :max-lines="1"
          >{{ book.publicationDate }}</v-clamp
        >
        <v-clamp
          class="this-book-publication-house pa-0 ma-0"
          autoresize
          :max-lines="1"
          >{{ book.publishingHouse }}</v-clamp
        >
      </v-col>
    </v-row>

    <v-row class="this-row-2 ma-0" justify="space-between">
      <v-col class="pa-0 ma-0">
        <v-row class="pa-0 ma-0">
          <v-col class="pa-0 ma-0">
            <v-row class="pa-0 ma-0" cols="auto">
              <div class="this-read-num">{{ book.readAmt || 0 }}</div>
              <div class="this-read-num-s">人阅读</div>
            </v-row>
          </v-col>

          <v-col class="pa-0 ma-0" cols="auto" v-show="recentReadStudent">
            <v-row class="this-recent-read-container pa-0 ma-0">
              <div class="this-recent-read">
                {{ recentReadStudent ? recentReadStudent.name : "" }}
              </div>
              <div class="this-recent-read-s">在读</div>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-col class="pa-0 ma-0" cols="auto"
        ><pc-book-detail-btn @clickBtn="clickBtn">{{
          btnText
        }}</pc-book-detail-btn></v-col
      >
    </v-row>

    <v-row class="this-row-3 pa-0"></v-row>

    <v-row class="this-row-4 ma-0" @click="expand = !expand">
      <v-col class="pa-0 ma-0">
        <div class="this-book-desc-s">简介</div>
        <p ref="desc" class="this-book-desc" :class="{ expand: expand }">
          {{ book.desc || "暂无简介" }}
        </p>
        <v-row
          v-show="showExpand"
          class="this-book-desc-more pa-0"
          justify="center"
        >
          <v-icon
            v-if="book.desc"
            :class="{ arrowTransform: expand, arrowTransformReturn: !expand }"
            size="18px"
            color="#4D9F7C"
          >
            icon-down_nor_icon
          </v-icon>
        </v-row>
      </v-col>
    </v-row>

    <c-loading v-show="showCLoading"></c-loading>
  </v-col>
</template>

<script>
import {
  getBookDetail,
  getBookDetailRecentReadStudent,
  getBookDetailReadState,
} from "@/api/api-pc";
import { isEmpty } from "@/views/common/web/isEmpty";
import VClamp from "vue-clamp";
import CLoading from "@/components/c-loading/c-loading";
import CImgBookCover from "@/components/c-img-book-cover/c-img-book-cover";
import PcBookDetailBtn from "@/views/pc/components/pc-book-detail-btn";
export default {
  name: "pc-book-detail-info",
  props: {
    isTeacher: {
      type: Boolean,
      default: false,
    },
    bookId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showCLoading: true, //Loading
      expand: true, //书籍描述是否展开
      showExpand: false, //是否显示展开按钮s
      book: {}, //服务期获取到的书籍对象
      recentReadStudent: null, //当前在读的学生对象
      btnText: "开启阅读", //按钮文本
      bookCfi: "", //上次阅读的位置
    };
  },
  components: {
    VClamp,
    CLoading,
    CImgBookCover,
    PcBookDetailBtn,
  },
  computed: {},
  methods: {
    //网络请求
    getData() {
      let userRole = this.isTeacher ? "教师" : "学生"; //用户角色
      getBookDetail(this.bookId)
        .then((res) => {
          if (!res) {
            console.log(">>>>>", "获取" + userRole + "书籍详情错误");
            this.showCLoading = false;
          } else {
            console.log(">>>>>", "获取" + userRole + "书籍详情成功");
            this.book = res;
            setTimeout(() => {
              this.showCLoading = false;
              this.expand = false;
            }, 100);
          }
        })
        .catch(({ res, statusCode }) => {
          console.log(">>>>>", "获取" + userRole + "书籍详情失败");
          this.showCLoading = false;
          if (!res || !res.message) {
            return;
          }

          this.showSnackbar({ msg: res.message });
        });
      getBookDetailRecentReadStudent(this.bookId)
        .then((res) => {
          if (!res && !res.user) {
            console.log(">>>>>", "获取" + userRole + "书籍详情在读学生错误");
          } else {
            console.log(">>>>>", "获取" + userRole + "书籍详情在读学生成功");
            this.recentReadStudent = res.user;
          }
        })
        .catch(({ res, statusCode }) => {
          console.log(">>>>>", "获取" + userRole + "书籍详情在读学生失败");
          if (!res || !res.message) {
            return;
          }

          this.showSnackbar({ msg: res.message });
        });
      getBookDetailReadState(this.bookId)
        .then((res) => {
          if (!res) {
            console.log(">>>>>", "获取" + userRole + "书籍详情阅读状态错误");
          } else {
            console.log(">>>>>", "获取" + userRole + "书籍详情阅读状态成功");
            if (isEmpty(res.lastReadCfi)) {
              this.btnText = "开启阅读";
            } else {
              this.btnText = "继续阅读";
            }
            this.bookCfi = res.lastReadCfi;
          }
        })
        .catch(({ res, statusCode }) => {
          console.log(">>>>>", "获取" + userRole + "书籍详情阅读状态失败");
          if (!res || !res.message) {
            return;
          }

          this.showSnackbar({ msg: res.message });
        });
    },
    //点击按钮
    clickBtn() {
      if (this.isTeacher) {
        this.read("pcTeacherReader"); //打开教师阅读器
      } else {
        this.read("pcReader"); //打开学生阅读器
      }
    },
    //打开阅读器
    read(readName) {
      this.$router.push({
        name: readName,
        query: {
          bookId: this.bookId,
          bookCfi: this.bookCfi,
        },
      });
    },
  },
  watch: {
    //监听expand属性的变化，来判断当前文本的高度是否大于3行，如果大于3行则显示下拉按钮
    expand(val) {
      if (this.$refs.desc.clientHeight > 90 && !this.showExpand) {
        this.showExpand = true;
      }
    },
  },
  created() {
    this.getData();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.this-row-1 {
  height: 304px;
  padding: 80px 80px 0px;
  .this-book-img {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.14);
  }
  .this-col-2 {
    position: relative;
    margin: 0px 0px 0px 48px;
    .this-book-name {
      line-height: 32px;
      margin: 14px 0px 0px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #444444;
    }
    .this-book-author {
      line-height: 29px;
      margin: 35px 0px 0px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
    }
    .this-book-publication-date {
      position: absolute;
      bottom: 53px;
      left: 0px;
      line-height: 29px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
    .this-book-publication-house {
      position: absolute;
      bottom: 14px;
      left: 0px;
      line-height: 29px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
  }
}

.this-row-2 {
  position: relative;
  height: 141px;
  padding: 40px 80px 0px;
  .this-read-num {
    height: 32px;
    line-height: 32px;
    margin-top: 10px;
    font-size: 35px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #4d9f7c;
  }
  .this-read-num-s {
    height: 29px;
    line-height: 29px;
    margin-top: 15px;
    margin-left: 6px;
    font-size: 22px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
  }
  .this-recent-read-container {
    position: absolute;
    top: 55px;
    left: 277px;
    .this-recent-read {
      height: 29px;
      line-height: 29px;
      font-size: 26px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4d9f7c;
    }
    .this-recent-read-s {
      height: 29px;
      line-height: 29px;
      margin-left: 6px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
    }
  }
}

.this-row-3 {
  height: 1px;
  margin: 0px 80px;
  background: #f0f0f0;
}

.this-row-4 {
  padding: 38px 80px 0px;
  .this-book-desc-s {
    height: 20px;
    line-height: 20px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #444444;
  }
  .this-book-desc {
    display: -webkit-box;
    line-height: 30px;
    margin: 19px 0px 0px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
    letter-spacing: 1px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
    &.expand {
      display: block;
    }
  }
  .this-book-desc-more {
    margin-top: 20px;
  }
}

.arrowTransform {
  transition: 0.3s;
  transform-origin: center;
  transform: rotateZ(180deg);
}
.arrowTransformReturn {
  transition: 0.3s;
  transform-origin: center;
  transform: rotateZ(0deg);
}
</style>
