<template>
  <v-col class="this-item-container pa-0">
    <v-row class="pa-0 ma-0" align="center">
      <v-img
        class="this-item-avatar"
        :src="avatar"
        width="36"
        height="36"
        max-width="36"
        max-height="36"
        aspect-ratio="1"
      />
      <v-clamp class="this-item-name pa-0" autoresize :max-lines="1">{{
        item.user.name
      }}</v-clamp>
      <v-clamp class="this-item-info pa-0" autoresize :max-lines="1">{{
        info
      }}</v-clamp>
    </v-row>

    <v-row class="this-item-note pa-0">{{ item.note }}</v-row>

    <v-row class="this-item-other pa-0" justify="space-between">
      <v-col class="pa-0 ma-0" cols="auto">
        <div class="this-item-date">{{ date }}</div>
      </v-col>

      <v-col class="this-item-like pa-0 ma-0" cols="auto" @click="clickLike">
        <v-row class="pa-0 ma-0" align="center">
          <v-icon :color="isLiked ? '#4D9F7C' : '#707F8D'" size="16"
            >icon-Fabulous</v-icon
          >
          <div class="this-item-like-num" :class="{ liked: isLiked }">
            {{ likeNum }}
          </div>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="this-item-line pa-0"></v-row>
  </v-col>
</template>

<script>
import { postBookDetailAfterReadingLike } from "@/api/api-pc";
import PlaceholderImg from "@/assets/img_placeholder_avatar_s.png";
import VClamp from "vue-clamp";
export default {
  name: "pc-book-detail-note-item",
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      likeNum: 0, //点赞数
    };
  },
  components: {
    VClamp,
  },
  computed: {
    avatar() {
      //头像
      if (this.item) {
        if (this.item.user) {
          return this.item.user.headImg;
        } else {
          return PlaceholderImg;
        }
      } else {
        return PlaceholderImg;
      }
    },
    info() {
      //学校班级信息
      if (this.item) {
        if (
          this.item.school &&
          this.item.school.name &&
          this.item.class &&
          this.item.class.name
        ) {
          return this.item.school.name + "/" + this.item.class.name;
        } else if (this.item.school && this.item.school.name) {
          return this.item.school.name;
        } else if (this.item.class && this.item.class.name) {
          return this.item.class.name;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    date() {
      return this.$moment(this.item.createdAt * 1000).format("MM-DD HH:mm");
    },
  },
  methods: {
    clickLike() {
      let userRole = this.isTeacher ? "教师" : "学生"; //用户角色
      postBookDetailAfterReadingLike(this.item.id)
        .then((res) => {
          console.log(">>>>>", userRole + "书籍详情读书笔记点赞成功");
          this.likeNum++;
          this.isLiked = true;
        })
        .catch(({ res, statusCode }) => {
          console.log(">>>>>", userRole + "书籍详情读书笔记点赞失败");
          if (!res || !res.message) {
            return;
          }

          this.showSnackbar({ msg: res.message });
        });
    },
  },
  watch: {},
  created() {
    this.isLiked = this.item.praised === 1;
    this.likeNum = this.item.praisedAmt;
  },
};
</script>

<style lang='scss' scoped>
.this-item-container {
  margin: 0px 0px 30px;
  .this-item-avatar {
    background: #d8d8d8;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.12);
    border-radius: 26px;
  }
  .this-item-name {
    line-height: 25px;
    margin-left: 11px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #444444;
  }
  .this-item-info {
    line-height: 22px;
    margin-left: 12px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
  .this-item-note {
    min-height: 30px;
    line-height: 30px;
    margin: 9px 0px 0px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
    white-space: normal;
    word-break: break-all;
    word-wrap: break-word;
  }
  .this-item-other {
    margin: 30px 0px 0px;
    .this-item-date {
      line-height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
    .this-item-like {
      width: 64px;
      .this-item-like-num {
        line-height: 20px;
        margin-left: 10px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #707f8d;
        letter-spacing: 1px;
        &.liked {
          color: #4d9f7c;
        }
      }
    }
  }
  .this-item-line {
    height: 1px;
    margin: 16px 0px 0px;
    background: #d8d8d8;
  }
}
</style>