<template>
  <v-row
    class="pc-book-detail-btn-container pa-0 mx-0"
    align="center"
    justify="center"
    @click="clickBtn"
  >
    <slot></slot>
  </v-row>
</template>

<script>
export default {
  name: "pc-book-detail-btn",
  props: {
    bookId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isSelected: false,
    };
  },
  components: {},
  computed: {},
  methods: {
    clickBtn() {
      this.$emit("clickBtn");
    },
  },
  watch: {},
  created() {},
};
</script>

<style lang='scss' scoped>
.pc-book-detail-btn-container {
  width: 140px;
  height: 44px;
  line-height: 20px;
  background: #8dcbb0;
  border-radius: 22px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.pc-book-detail-btn-container:hover {
  background: #4d9f7c;
}
</style>