<template>
  <v-col class="pc-book-detail-container pa-0 ma-0">
    <div class="pc-book-detail-top-bar">
      <pc-frame :style="{ background: 'white' }"> </pc-frame>
    </div>

    <v-row class="pc-book-detail-row pa-0" justify="center">
      <div class="white">
        <v-col class="pc-book-detail-col pa-0 ma-0">
          <pc-book-detail-info
            :isTeacher="myIsTeacher"
            :bookId="bookId"
          ></pc-book-detail-info>

          <pc-book-detail-recommend
            :isTeacher="myIsTeacher"
            :bookId="bookId"
          ></pc-book-detail-recommend>

          <pc-book-detail-note
            v-if="mySchoolId"
            :isTeacher="myIsTeacher"
            :bookId="bookId"
            :schoolId="mySchoolId"
          ></pc-book-detail-note>
        </v-col>
      </div>
    </v-row>
  </v-col>
</template>

<script>
import PcMixins from "@/views/pc/_mixins/pc-mixins.js";
import UserDataMixins from "@/views/pc/index/_mixins/pc-user-mixins.js";
import PcFrame from "@/views/pc/index/pc-frame";
import PcBookDetailInfo from "@/views/pc/components/pc-book-detail-info";
import PcBookDetailRecommend from "@/views/pc/components/pc-book-detail-recommend";
import PcBookDetailNote from "@/views/pc/components/pc-book-detail-note";
export default {
  name: "pc-book-detail",
  mixins: [PcMixins, UserDataMixins],
  props: {},
  data() {
    return {
      bookId: 0,
      mySchoolId: 0,
      myIsTeacher: false,
    };
  },
  components: {
    PcFrame, //顶部栏
    PcBookDetailInfo, //书籍信息
    PcBookDetailRecommend, //书籍推荐
    PcBookDetailNote, //读书笔记
  },
  computed: {},
  methods: {},
  watch: {
    schoolId(val) {
      this.mySchoolId = val;
    },
    isTeacher(val) {
      this.myIsTeacher = val;
    },
  },
  created() {
    this.bookId = this.$route.params.bookId;
    this.mySchoolId = this.schoolId;
    this.myIsTeacher = this.isTeacher;
  },
};
</script>

<style lang="scss" scoped>
.pc-book-detail-container {
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  overflow: hidden;
  .pc-book-detail-top-bar {
    box-shadow: 0 1px 1px #f0f0f0;
  }
  .pc-book-detail-row {
    height: calc(100% - 78px);
    margin-top: 1px;
    overflow: auto;
    .pc-book-detail-col {
      width: 900px;
      height: 100%;
    }
  }
}
</style>
